import { parse } from "psl";

declare global {
  interface Window {
    endorsely_referral: string | null;
    endorsely: EndorselyProvider;
  }
}

const API_URL = "https://app.endorsely.com/api/public";

const parsed = parse(window.location.hostname);
const endorsely_domain = parsed.input;

const endorsely_params = new URLSearchParams(window.location.search);
const endorsely_referral_param_key = endorsely_params.get("ref")
  ? "ref"
  : endorsely_params.get("aff")
  ? "aff"
  : endorsely_params.get("via")
  ? "via"
  : null;

const endorsely_referral_param_value =
  endorsely_params.get("aff") ||
  endorsely_params.get("ref") ||
  endorsely_params.get("via") ||
  null;

const endorsely_script = document.querySelector("script[data-endorsely]");

const endorsely_organization_id = endorsely_script
  ? endorsely_script.getAttribute("data-endorsely")
  : null;

const endorsely_set_cookie = (referral: string, duration: number) => {
  var expireTime = new Date();
  expireTime.setTime(expireTime.getTime() + duration * 24 * 60 * 60 * 1000);
  const expireString = "expires=" + expireTime.toUTCString();
  document.cookie =
    "endorsely_referral=" +
    referral +
    ";" +
    expireString +
    ";path=/;domain=" +
    endorsely_domain +
    ";samesite=none;secure";
};

const endorsely_get_cookie = () => {
  const decodeCookies = decodeURIComponent(document.cookie);
  const cookieArray = decodeCookies.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let currCookie = cookieArray[i];
    while (currCookie.charAt(0) == " ") {
      currCookie = currCookie.substring(1);
    }
    if (currCookie.indexOf("endorsely_referral=") == 0) {
      return currCookie.substring(
        "endorsely_referral=".length,
        currCookie.length
      );
    }
  }
  return "";
};

const endorsely_remove_cookie = () => {
  document.cookie =
    "endorsely_referral=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=" +
    endorsely_domain +
    ";samesite=none;secure";
};

const endorsely_referral_saved =
  endorsely_get_cookie() && endorsely_get_cookie() !== "null"
    ? endorsely_get_cookie()
    : null;

class EndorselyProvider {
  async create() {
    const organizationId = endorsely_organization_id;
    const code = endorsely_referral_param_value;

    const payload = {
      organizationId,
      code,
    };

    if (
      !endorsely_organization_id ||
      !endorsely_domain ||
      !endorsely_referral_param_key
    ) {
      return;
    }

    try {
      const resp = await fetch(`${API_URL}/track`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const affiliateInfo = await resp.json();
      if (affiliateInfo.referral) {
        window.endorsely_referral = affiliateInfo.referral;
        endorsely_set_cookie(
          affiliateInfo.referral,
          affiliateInfo.cookie_duration
        );
      } else {
        endorsely_remove_cookie();
        window.endorsely_referral = null;
      }
      return affiliateInfo.referral;
    } catch (error) {
      endorsely_remove_cookie();
      window.endorsely_referral = null;
      return "error";
    }
  }
}

const endorsely = new EndorselyProvider();
window.endorsely = endorsely;

if (
  endorsely_referral_param_key &&
  endorsely_referral_param_value &&
  !endorsely_referral_saved
) {
  endorsely.create();
} else if (endorsely_referral_saved) {
  window.endorsely_referral = endorsely_referral_saved;
} else {
  window.endorsely_referral = null;
}
